@import "../partials/base";
.navbar {
    #logo1 {
        display: none;
    }
}
html, body {
    background: #fff;
}
.header-bar {
    background: transparent;
    box-shadow: none;
    &.was-scroll {
        background: #fff;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 5px 0px;
    }
}

.new-login-wrapper {
    background: #fff get_prefix_url("imgs/shopify/bg.svg") no-repeat right top;
    padding: 0 16px;
    .new-login-wrap {
        max-width: 1010px;
        height: 700px;
        padding-top: 64px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .login-info {
            width: 56%;
            color: #333;
            max-width: 465px;
        }
        .login-img {
            width: 46%;
            text-align: right;
            img {
                max-width: 86%;
            }
        }
    }
    .fs-form-wrap {
        .shopify-logo {
            padding-bottom: 40px;
            display: flex;
            align-items: center;
            > div:nth-child(1) img {
                height: 37px
            }
            > div:nth-child(2) {
                margin: 0 15px;
                img {
                    width: 28px;
                }
            }
            > div:nth-child(3) img {
                height: 30px;
            }
        }
        .fsf-title {
            color: #333;
            font-size: 28px;
            line-height: 1.3;
            font-weight: bold;
        }
        .fsf-subtitle {
            margin-top: 4px;
            color: #333;
            font-size: 16px;
            padding-bottom: 18px;
        }
        .form-item {
            margin-top: 8px;
            .form-control {
                border: 1px solid #e7e7e7;
                border-radius: 0;
                background: #fff;
                height: 48px;
                padding-left: 15px;
                color: #000;
                font-size: 15px;
                font-weight: bold;
                outline: none;
                &:focus {
                    border-color: #00c4b3;
                    box-shadow: none;
                }
                &::placeholder {
                    color: #999;
                }
            }
        }
        .form-flex {
            margin-top: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            a {
                font-size: 16px;
                font-weight: bold;
                color: #324a5e;
                img {
                    margin-right: 4px;
                }
            }
            .btn {
                width: 224px;
                &[disabled] {
                    background: #e7e7e7!important;
                    cursor: not-allowed;
                    opacity: 1;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .new-login-wrapper {
        background: #fff get_prefix_url("imgs/shopify/bg_m.svg") no-repeat right top;
        padding: 0;
        .new-login-wrap {
            flex-direction: column;
            justify-content: center;
            padding: 60px 16px 30px;
            height: 700px;
            .login-info {
                width: 100%;
                order: 2;
                text-align: center;
                margin-top: 24px;
                max-width: 100%;
            }
            .login-img {
                width: 100%;
                order: 1;
                text-align: center;
                img {
                    max-width: 98%;
                }
            }
        }
        .fs-form-wrap {
            text-align: left;
            .shopify-logo {
                padding-bottom: 20px;
                > div:nth-child(1) img {
                    height: 30px
                }
                > div:nth-child(2) {
                    margin: 0 15px;
                    img {
                        width: 24px;
                    }
                }
                > div:nth-child(3) img {
                    height: 26px;
                }
            }
            .fsf-title {
                font-size: 24px;
                line-height: 1.4;
            }
            .fsf-subtitle {
                margin-top: 0;
            }
            .form-flex .btn {
                width: 224px!important;
                padding: 14px 0 13px !important;
                margin: 0;
            }
        }
    }
}

@media screen and (max-width: 370px) {
    .new-login-wrapper .new-login-wrap {
        padding: 30px 16px 0px;
        height: 568px;
    }
    .new-login-wrapper .fs-form-wrap .form-flex .btn {
        width: 180px!important;
    }
}