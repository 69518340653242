/*
Boxful sass variables
*/

@import "../config/local";
$fa-font-path: "./webfonts";

//color
$boxfulGreen: #00c4b4;
$boxfulYellow: #f7d33f;
$boxfulYellowSelected: #ddb615;
$boxfulXLSelectedColor: #f2ce3d;
$boxfulBackgroundGreen: #e9f8f7;
$boxfulBackgroundLightGrey: #f1f2f2;
$boxfulBorderGrey: #d1d3d4;
$boxfulTitleGrey: #cccccc;
$boxfulCheckoutborder: #cccccc;
$boxfulCheckoutXLBoxBKColor: #f2f2f3;
$boxfulLandingGreenBKColor: #00c4b2;
$boxfulBelowMinChargeColor: #FC3C3E;
$boxfulInputBorderColor: #e6e7e8;
//ikea
$ikeaDarkBlue: #035bc9;
$ikeaLightBlue: #E7F3FF;

//font
//$boxfulFontFamily:'Lato','source-han-sans-traditional','Noto Sans TC' ,'Heiti TC', '微軟正黑體', 'Microsoft JhengHei', sans-serif;
$boxfulFontFamily:Lato,Helvetica,Arial,PingFang HK,Noto Sans,Noto Sans TC,Microsoft JhengHei,sans-serif;
//responsive threshold
$responStart1024:"1024px";
$responStart980:"980px";
$responStart767:"767px";
$responEnd1024:$responStart980 + 1px;
$responEnd980:$responStart767 + 1px;

$information-notebook: "screen and (max-width: 1200px)";
$information-tablet: "screen and (max-width: 992px)";
$information-phone: "screen and (max-width : 768px)";
$information-small-phone: "screen and (max-width : 576px)";



//error border
@mixin errorinputBorder{
	border: 2px solid red;
}


//title underline (img)
%titleUnderLineImg{
	margin-top: 10px;
	margin-bottom: 15px;
}
// reg and loc and checkout switch detail
%titleContentDetail{
	font-size: 21px;
    line-height: 1.7;
    padding-bottom: 5px;
}


@mixin input($width: 100%, $cursor: pointer) {
	border: 2px solid $boxfulBorderGrey;
	margin-right: 5px;
	font-size: 12px;
	width: $width;
    cursor: $cursor;
	background: #fff;
	border-radius: 4px;
	color: #898989;
	height: 34px;
	min-height: 34px;
	padding: 4px 5px;
	&:focus {
		border-color: $boxfulGreen;
	}
}
